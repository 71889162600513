'use client';

import { CustomLink, ScrollAnimation } from '@/ui';
import { Skeleton } from '@mantine/core';
import { useTranslations } from 'next-intl';
import Image from 'next/image';
import useSWR from 'swr';
import classnames from './external-info.module.css';

type Banner = {
  id: number;
  link: string;
  title: string;
  image: string;
};

const YEAR = new Date().getFullYear();

export default function Banner() {
  const t = useTranslations();
  const { data: banner, isLoading } = useSWR<Banner>(['/main-page/get-state-banner']);

  return isLoading ? (
    <Skeleton height={84} radius="0" />
  ) : banner?.image ? (
    <ScrollAnimation>
      <CustomLink
        href={banner?.link || '/'}
        target="_blank"
        className={classnames?.banner}
        aria-label={`${t('O‘zbekiston Respublikasi')}: ${YEAR}`}
      >
        <Image src={banner?.image || ''} alt={`${t('O‘zbekiston Respublikasi')}: ${YEAR}`} width={380} height={80} />
      </CustomLink>
    </ScrollAnimation>
  ) : null;
}
